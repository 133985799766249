body {
  box-sizing: border-box;
  flex-direction: column;
  height: 100dvh;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  display: flex;
}

main {
  text-align: center;
  flex-direction: column;
  flex: 0 0 89vh;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

footer {
  box-sizing: border-box;
  background: #f8f8f8;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10dvh;
  font-size: 1.5rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

footer span {
  cursor: pointer;
  margin: 0 20px;
}

h1 {
  align-self: flex-start;
  margin-top: 0;
  padding-top: 1svh;
  padding-left: 3svh;
}

h2 {
  flex-grow: 0;
  flex-basis: 20%;
  margin: 5px;
  font-size: 3rem;
  font-weight: 400;
}

sup {
  font-size: 1rem;
}

a {
  text-underline-offset: 5px;
}

#current {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 80svh;
  display: flex;
}

#current p {
  padding: 2px 15px;
  font-size: 1rem;
}

#current a, #current a:visited {
  color: #00f;
}

#current #instructions {
  flex-basis: 20%;
}

form {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 300px;
  height: 200px;
  margin: auto;
  display: flex;
}

form h3 {
  margin-bottom: 0;
}

form input[type="password"] {
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}

form input[type="submit"] {
  color: #fff;
  background-color: #5151b0;
  border: 1px solid #5151b0;
  border-radius: 5px;
  padding: 5px 10px;
}

.m-0 {
  margin: 0 auto;
}

.icon {
  width: 30px;
  height: auto;
}

.click:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

.transparent {
  opacity: 0;
}

.opaque {
  opacity: 1;
}

.modal {
  z-index: 999;
  width: 90vw;
  height: 90vh;
  position: fixed;
  top: 0;
  left: 0;
}

.address {
  font-size: 1.5rem;
}

.phone {
  box-sizing: border-box;
  color: #333;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  display: inline-flex;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.phone svg {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

#phone-lang {
  justify-content: center;
  align-items: center;
  height: 100px;
  display: flex;
}

.language {
  margin-left: 10px;
}

#quickhelp {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  height: 60px;
  margin-bottom: 20px;
  display: flex;
}

#camera-launch {
  cursor: pointer;
  width: 50px;
  margin: 0 auto;
}

#join {
  cursor: pointer;
  width: 40px;
  position: fixed;
  top: 3svh;
  right: 10px;
}

main#app {
  overflow: hidden;
}
/*# sourceMappingURL=index.1f319e7c.css.map */
