.m-0 {
  margin: 0 auto;
}

.icon {
  width: 30px;
  height: auto;
}

.click:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

.transparent {
  opacity: 0;
}

.opaque {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 90vw;
  height: 90vh;
}