body {
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  box-sizing: border-box;
}

main {
  flex: 0 0 89vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  text-align: center;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  height: 10dvh;
  width: 100%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
}

footer span {
  cursor: pointer;
  margin: 0 20px;
}

h1 {
  margin-top: 0px;
  /* flex-basis: 20%; */
  align-self: flex-start;
  padding-left: 3svh;
  padding-top: 1svh;
}

h2 {
  margin: 5px;
  font-size: 3rem;
  flex-grow: 0;
  font-weight: 400;
  flex-basis: 20%;
}

sup {
  font-size: 1rem;
}

a {
  text-underline-offset: 5px;
}