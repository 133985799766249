form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 200px;
  margin: auto;
  align-items: baseline;
  justify-content: space-between;
}

form h3 {
  margin-bottom: 0;
}

form input[type="password"] {
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 100%;
}

form input[type="submit"] {
  background-color: rgb(81, 81, 176);
  border: 1px solid rgb(81, 81, 176);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}