#current {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 80svh;
}

#current p {
  padding: 2px 15px;
  font-size: 1rem;
}

#current a,
#current a:visited {
  color: blue;
}

#current #instructions {
  flex-basis: 20%;
}