.address {
  font-size: 1.5rem;
}

.phone {
  border: 1px solid #efefef;
  padding: 10px 15px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  color: #333;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
}

.phone svg {
  margin-right: 5px;
  width: 20px;
  height: auto;
}

#phone-lang {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.language {
  margin-left: 10px;
}

#quickhelp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 50vw;
  margin-bottom: 20px;
}

#camera-launch {
  cursor: pointer;
  margin: 0 auto;
  width: 50px;
}
